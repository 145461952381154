import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-suite-app-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatIconModule, TranslateModule],
  templateUrl: './suite-app-card.component.html',
  styleUrl: './suite-app-card.component.scss'
})
export class SuiteAppCardComponent {
  @Input() content: any;
}
