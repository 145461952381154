import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-of-services',
  standalone: true,
  imports: [],
  templateUrl: './terms-of-services.component.html',
  styleUrl: './terms-of-services.component.scss'
})
export class TermsOfServicesComponent {

}
