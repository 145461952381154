import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '../shared/button/button.component';
import { contactUsSubjects } from '../../constants/contact-subjects';

@Component({
  selector: 'app-contact-us',
  host: {id: 'contactUs'},
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatInputModule, ReactiveFormsModule, ButtonComponent],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {

  public contactForm = new FormGroup({
    subject: new FormControl(''),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.pattern('^[- +()0-9]+$')),
    organization: new FormControl('', Validators.required),
    summary: new FormControl('')
  })

  public subjects = contactUsSubjects;

  constructor(private translateService: TranslateService) {}

  public submitContactRequest() {
    const formattedForm = {...this.contactForm.value};

    // If a subject is selected, translate it to english
    if (formattedForm.subject) {
      const currentLanguage: string = this.translateService.currentLang;
      this.translateService.currentLang = 'en';
      formattedForm.subject = this.translateService.instant(`component.contactUs.subject.${formattedForm.subject}`);
      this.translateService.currentLang = currentLanguage;
    }

    // TODO: Logic to process request
    // TODO: Confirmation modal

    console.log(formattedForm);
    this.contactForm.reset();
  }

}
