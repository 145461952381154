import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

/**
 *
 */
export interface Member {
  id: number;
  name: string;
  role: string;
  content: string;
  order: number;
  // image url
  img: string;
  location: string;
}

// Injectable DTO object
/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class MemberAdapter implements Adapter<Member> {
  /**
   * Function for create the different member
   *
   * @param item the member to create
   * @returns Member
   */
  adapt(item: any): Member {
    const member: Member = {
      id: item.id,
      name: item.name,
      role: item.role,
      content: item.content,
      order: item.order,
      img: item.featured_image.medium,
      location: item.location,
    };
    return member;
  }
}
