import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { consultingItem } from '../../../../constants/navigation';
import { consultingServices } from '../../../../constants/consulting-services';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-consulting-services',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatExpansionModule, MatIconModule],
  templateUrl: './consulting-services.component.html',
  styleUrl: './consulting-services.component.scss'
})
export class ConsultingServicesComponent implements OnInit, OnDestroy {
  public serviceItems = consultingItem;
  public service = this.setService(consultingItem.children![0].nav);

  private subscription: Subscription = new Subscription();;

  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.params.subscribe((params)=> {
        this.service = this.setService(params['service'] || consultingItem.children![0].nav);
      })
    );
  }

  private setService(nav: string) {
    return {
      nav: nav,
      value: consultingServices[nav]
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
