export interface SuiteApp {
    code: string,
    icon: string,
    name: string,
    description: string,
    features: string[],
    state: 'comingSoon' | 'demoSoon' | 'available'
}

export const availableSuiteApps: SuiteApp[] = [
    {
        code: 'PMS',
        icon: "/assets/images/apps/rms/icon.png",
        name: 'Purchasing & Procurement',
        description: 'PMS - Procurement & Purchasing Management System, streamlines sourcing & acquiring essential goods for humanitarian operations. Simplifying procurement tasks, boosting efficiency, & ensuring timely delivery of critical supplies.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'comingSoon'
    },
    {
        code: 'SCMS',
        icon: "/assets/images/apps/cms/icon.png",
        name: 'Supply Chain',
        description: 'SCMS - Supply Chain Management System, optimizes inventory tracking, logistics, and resource distribution. It simplifies supply chain operations, ensuring timely delivery and efficient management of critical supplies on the ground.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'demoSoon'
    },
    {
        code: 'WMS',
        icon: "/assets/images/apps/wms/icon.png",
        name: 'Warehouse & Stock',
        description: 'WMS - Warehouse Management System, designed for humanitarian field operations, streamlines inventory tracking, ensures efficient reporting, and simplifies collaboration, enabling teams to effectively manage, track, and optimize stock on the ground.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'available'
    }
]