import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

type Style = 'flat' | 'outline' | 'basic';
type Color = 'black' | 'gray' | 'accent' | 'accent-gradient'

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() style: Style = 'outline';
  @Input() color: Color = 'accent';
  @Input() arrow = false;
  @Input() disabled = false;

}
