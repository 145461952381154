import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../shared/button/button.component';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { consultingItem, navigationItems } from '../../constants/navigation';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, ButtonComponent, MatInputModule, ReactiveFormsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  public currentYear = new Date().getFullYear();
  public newsletterEmail = new FormControl('', [Validators.required, Validators.email]);
  public navItems = navigationItems;
  public serviceItems = consultingItem;

  onSubscribe() {
    console.log(this.newsletterEmail.valid)
  }

}
