import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { TermsOfServicesComponent } from './pages/other/terms-of-services/terms-of-services.component';
import { PrivacyPolicyComponent } from './pages/other/privacy-policy/privacy-policy.component';
import { ReliefAppsComponent } from './pages/relief-apps/relief-apps.component';

export const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'consulting', component: ConsultingComponent },
    { path: 'consulting/:service', component: ConsultingComponent },
    { path: 'relief-apps', component: ReliefAppsComponent },
    { path: 'terms-of-services', component: TermsOfServicesComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', redirectTo: '/home' }, 
];
