import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNavigationService } from '../../services/page-navigation.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss'
})
export class HowItWorksComponent {
  constructor(public navigationSerice: PageNavigationService) {}
}
