const getTranslationKeyForServices = (key: string, serviceKey: string, section?: number) => {
    let translationKey = `pages.consulting.service.${serviceKey}`
    if (typeof section == 'undefined') {
        translationKey += `.${key}`;
    } else {
        translationKey += `.section.${section}.${key}`;
    }
    return translationKey;
}
const getSections = (serviceKey: string, n: number) => {
    const sections: {title: string, summary: string}[] = [];
    for(let i = 0; i < n; i++) {
        sections.push({
            title: getTranslationKeyForServices('title', serviceKey, i),
            summary: getTranslationKeyForServices('summary', serviceKey, i)
        });
    }
    return sections;
}

/**
 * Object defining needed properties of a navigation item.
 * 
 * - key: Used to get the correct translation for the item and as identificator.
 * - nav: Used as the route of the item.
 * - children: Used if the item has sub-routes available.
 */
interface Services {
    [key: string]: {
        title: string,
        summary: string,
        image: string,
        sections: {
            title: string
            summary: string
        }[]
    }
}

/**
 * Make sure there is an entry for each consulting item children, name it after its 'nav' key
 */
export const consultingServices: Services = {
    'info-management': {
        image: '/assets/images/pages/consulting/placeholder.png',
        title: getTranslationKeyForServices('title', 'infoManagement'),
        summary: getTranslationKeyForServices('summary', 'infoManagement'),
        sections: getSections('infoManagement', 1)
    },
}