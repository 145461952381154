import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

/**
 *
 */
export interface ProjectTestimonial {
  person: string;
  job: string;
  testimonial: string;
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectTestimonialAdapter implements Adapter<ProjectTestimonial> {
  /**
   * Function for create the different ProjectTestimonial
   *
   * @param item the ProjectTestimonial to create
   * @returns ProjectTestimonial
   */
  adapt(item: any) {
    const projectTestimonial = {
      person: item.person,
      job: item.job,
      testimonial: item.testimonial,
    };
    return projectTestimonial;
  }
}
