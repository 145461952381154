import { Component } from '@angular/core';
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-hiring-section',
  standalone: true,
  imports: [ButtonComponent, TranslateModule],
  templateUrl: './hiring-section.component.html',
  styleUrl: './hiring-section.component.scss'
})
export class HiringSectionComponent {

}
