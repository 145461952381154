<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <h4>Humanitarian Innovation Hub</h4>
        <h1>Bringing Solutions to those Changing the World</h1>
        <div class="flex">
            <div>
                <app-button [style]="'outline'" [color]="'black'" [arrow]="true" (click)="goTo('contactUs')">
                    {{"shared.talk" | translate}}
                </app-button>
            </div>
            <div>
                <p>
                    Humanitarians & Geeks. We are passionate about innovation. We believe that technological products can significantly enhance the impact of nonprofits and NGOs. Providing the right tools to the right people can make a real difference.
                </p>
            </div>
        </div>
        <div class="cover-images">
            <div class="img1"></div>
            <div class="img2"></div>
        </div>
    </div>

    <!-- Partners banner -->
    <div class="partners-banner">
        <app-partners-banner></app-partners-banner>
    </div>

    <!-- Page content -->
    <div class="sections-container">

        <!-- "Our Story" section -->
        <div class="flex">
            <div>
                <h2>Our Story</h2>
                <p>During the Hurricane Haiyan response in the Philippines, Raphael & Arturo, driven by a shared passion for technology & humanitarian aid, envisioned how innovation could transform their missions. Upon returning to Europe, they founded Relief Applications.</p>
                <p>Today, the mission of Relief Applications is to bring innovative solutions to the people who fight to alleviate poverty around the world!</p>
                <app-button [style]="'flat'" [color]="'black'" [arrow]="true">
                    {{"shared.learn" | translate}}
                </app-button>
            </div>
            <!-- Custom icon list -->
             <div>
                <app-custom-list [content]="ourStoryList"></app-custom-list>
             </div>
        </div>

        <!-- "About Us" section -->
        <div class="flex about-us">
            <div>
                <h2>About Us</h2>
                <p>{{'pages.home.aboutUs.p1' | translate}}</p>
                <p>{{'pages.home.aboutUs.p2' | translate}}</p>
                <p>{{'pages.home.aboutUs.p3' | translate}}</p>
                <p>{{'pages.home.aboutUs.p4' | translate}}</p>
                <!-- Meet our team -->
                <div class="meet-team">
                    <div class=member *ngFor="let member of firstFourMembers" [ngStyle]="{'background-image': 'url(' + member.img + ')'}">
                    </div>
                    <app-button [style]="'basic'" [color]="'black'" [arrow]="true">
                        {{"pages.home.aboutUs.meetTeam" | translate}}
                    </app-button>
                </div>
            </div>
            <div class="img"></div>
        </div>

        <!-- "What we do" section -->
        <div class="flex">
            <div>
                <h2>{{'pages.home.whatWeDo.title' | translate}}</h2>
                <p>{{'pages.home.whatWeDo.p1' | translate}}</p>
                <app-button [style]="'outline'" [color]="'black'" [arrow]="true" (click)="goTo('contactUs')">
                    {{"shared.talk" | translate}}
                </app-button>
            </div>
            <!-- Custom icon list -->
             <div>
                <app-custom-list [variant]="'red'" [content]="whatWeDoList"></app-custom-list>
             </div>
        </div>

        <app-apps-carrousel></app-apps-carrousel>

        <app-projects-row></app-projects-row>

        <!-- Hiring section -->
         <app-hiring-section></app-hiring-section>

        <!-- "Contact us" section -->
        <app-contact-us id="contactUs"></app-contact-us>
    </div>
</div>