import { Component } from '@angular/core';
import { AppCardComponent } from './components/app-card/app-card.component';
import { availableApps } from '../../constants/apps';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-apps-carrousel',
  standalone: true,
  imports: [CommonModule, AppCardComponent, ButtonComponent, TranslateModule],
  templateUrl: './apps-carrousel.component.html',
  styleUrl: './apps-carrousel.component.scss'
})
export class AppsCarrouselComponent {
  public availableApps = availableApps;
}
