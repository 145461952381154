import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-card',
  standalone: true,
  imports: [ButtonComponent, TranslateModule],
  templateUrl: './project-card.component.html',
  styleUrl: './project-card.component.scss'
})
export class ProjectCardComponent {
  @Input() content?: {
    title: string,
    desc: string,
    img: string
  }

  constructor() {
    console.log(this.content)
  }
}
