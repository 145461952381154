import { AfterViewInit, Component, HostListener, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-app-card',
  standalone: true,
  imports: [],
  templateUrl: './app-card.component.html',
  styleUrl: './app-card.component.scss'
})
export class AppCardComponent implements AfterViewInit {

  @Input() value: any;

  public originalWidth = 0;

  constructor(private viewRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    this.originalWidth = this.viewRef.element.nativeElement.clientWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.originalWidth = this.viewRef.element.nativeElement.clientWidth;
  }
}
