import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { HowItWorksComponent } from '../../components/how-it-works/how-it-works.component';
import { CarrouselComponent } from '../../components/shared/carrousel/carrousel.component';
import { ProjectsRowComponent } from "../../components/projects-row/projects-row.component";
import { SuitesRowComponent } from "../../components/suites-row/suites-row.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-relief-apps',
  standalone: true,
  imports: [CommonModule, ContactUsComponent, HowItWorksComponent, CarrouselComponent, ProjectsRowComponent, SuitesRowComponent, TranslateModule],
  templateUrl: './relief-apps.component.html',
  styleUrl: './relief-apps.component.scss'
})
export class ReliefAppsComponent {

}
