import { Component, ElementRef } from '@angular/core';
import { ButtonComponent } from '../../components/shared/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PartnersBannerComponent } from './components/partners-banner/partners-banner.component';
import { CustomListComponent, CustomListContent } from './components/custom-list/custom-list.component';
import { MemberService } from '../../services/members.service';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { PageNavigationService } from '../../services/page-navigation.service';
import { HiringSectionComponent } from '../../components/hiring-section/hiring-section.component';
import { AppsCarrouselComponent } from '../../components/apps-carrousel/apps-carrousel.component';
import { CarrouselComponent } from '../../components/shared/carrousel/carrousel.component';
import { ProjectsRowComponent } from "../../components/projects-row/projects-row.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    MatIconModule,
    TranslateModule,
    PartnersBannerComponent,
    CustomListComponent,
    ContactUsComponent,
    HiringSectionComponent,
    AppsCarrouselComponent,
    ProjectsRowComponent
],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  public ourStoryList: CustomListContent = [
    {
      icon: 'magic_star',
      title: 'pages.home.ourStory.list.1.title',
      content: 'pages.home.ourStory.list.1.content'
    },
    {
      icon: 'eye',
      title: 'pages.home.ourStory.list.2.title',
      content: 'pages.home.ourStory.list.2.content'
    },
    {
      icon: 'heart_circle',
      title: 'pages.home.ourStory.list.3.title',
      content: 'pages.home.ourStory.list.3.content'
    }
  ];
  public whatWeDoList: CustomListContent = [
    {
      icon: 'programming_arrow',
      title: 'pages.home.whatWeDo.list.1.title',
      content: 'pages.home.whatWeDo.list.1.content'
    },
    {
      icon: 'people',
      title: 'pages.home.whatWeDo.list.2.title',
      content: 'pages.home.whatWeDo.list.2.content'
    },
    {
      icon: 'monitor',
      title: 'pages.home.whatWeDo.list.3.title',
      content: 'pages.home.whatWeDo.list.3.content'
    }
  ];

  /**
   * Used to store first 4 members.
   * Initialized to 4 placeholder values so we can have a 'loading' state.
   */
  public firstFourMembers: any[] = ['', '', '', ''];

  /**
   * Gets all members, sorts them by order param and saves first four.
   * 
   * @param memberService Used to get the first four members of RA.
   */
  constructor(
    private memberService: MemberService,
    private navigationService: PageNavigationService
  ) {
    this.memberService.members.subscribe((res) => {
      const members = res.sort((a, b) => a.order - b.order);
      this.firstFourMembers = members.slice(0, 4);
    });
  }

  public goTo(id: string, route?: any[]) {
    this.navigationService.scrollTo(id, route);
  }

}
