export const getTranslationKeyForNavigation = (key: string, childrenKey?: string) => {
    let translationKey = `component.navigation.item.${key}`
    if (!childrenKey) {
        translationKey += `.value`;
    } else {
        translationKey += `.child.${childrenKey}`;
    }
    return translationKey;
}

/**
 * Object defining needed properties of a navigation item.
 * 
 * - key: Used to get the correct translation for the item.
 * - nav: Used as the route of the item.
 * - children: Used if the item has sub-routes available.
 */
export interface NavItem {
    key: string,
    nav: string,
    children?: NavItem[]
}

/**
 * NavItem for consulting section and childrens, will be used on the navigationItems constant.
 */
export const consultingItem: NavItem = {
    key: getTranslationKeyForNavigation('consulting'),
    nav: 'consulting',
    children: [
        {
            key: getTranslationKeyForNavigation('consulting', 'infoManagement'),
            nav: 'info-management'
        }, {
            key: getTranslationKeyForNavigation('consulting', 'mealAndResearch'),
            nav: 'meal-and-research'
        }, {
            key: getTranslationKeyForNavigation('consulting', 'trainingAndCapacity'),
            nav: 'training-and-capacity'
        }, {
            key: getTranslationKeyForNavigation('consulting', 'appDevAndMaintenance'),
            nav: 'app-dev-and-maintenance'
        }, {
            key: getTranslationKeyForNavigation('consulting', 'mobileWebHybrid'),
            nav: 'mobile-web-hybrid'
        }, {
            key: getTranslationKeyForNavigation('consulting', 'AIandTools'),
            nav: 'AI-and-tools'
        }
    ]
};

/**
 * NavItem array of available routes used on navigation components.
 */
export const navigationItems: NavItem[] = [
    consultingItem,
    {
        key: getTranslationKeyForNavigation('reliefApps'),
        nav: 'relief-apps',
        children: [
            {
                key: getTranslationKeyForNavigation('reliefApps', 'supplyChain'),
                nav: 'supply-chain'
            }, {
                key: getTranslationKeyForNavigation('reliefApps', 'warehouseStock'),
                nav: 'warehouse-stock'
            }, {
                key: getTranslationKeyForNavigation('reliefApps', 'caseManagement'),
                nav: 'case-management'
            }, {
                key: getTranslationKeyForNavigation('reliefApps', 'monitoringEvaluation'),
                nav: 'monitoring-evaluation'
            }, {
                key: getTranslationKeyForNavigation('reliefApps', 'wash'),
                nav: 'wash'
            }, {
                key: getTranslationKeyForNavigation('reliefApps', 'customApp'),
                nav: 'custom-app'
            }
        ]
    }, {
        key: getTranslationKeyForNavigation('projects'),
        nav: 'projects'
    }, {
        key: getTranslationKeyForNavigation('about'),
        nav: 'about'
    }, {
        key: getTranslationKeyForNavigation('pricing'),
        nav: 'pricing'
    }
]