import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';
import { MemberService } from '../../services/members.service';
import { AppsCarrouselComponent } from '../../components/apps-carrousel/apps-carrousel.component';
import { CarrouselComponent } from '../../components/shared/carrousel/carrousel.component';
import { MatIconModule } from '@angular/material/icon';
import { PageNavigationService } from '../../services/page-navigation.service';
import { ConsultingServicesComponent } from './components/consulting-services/consulting-services.component';
import { HowItWorksComponent } from '../../components/how-it-works/how-it-works.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectsRowComponent } from '../../components/projects-row/projects-row.component';

const humanitarianRoles = [
  'CEO',
  'Information Manager',
  'Business Development'
];

@Component({
  selector: 'app-consulting',
  standalone: true,
  imports: [CommonModule, ContactUsComponent, ProjectsRowComponent, MatIconModule, ConsultingServicesComponent, HowItWorksComponent, TranslateModule],
  templateUrl: './consulting.component.html',
  styleUrl: './consulting.component.scss'
})
export class ConsultingComponent {

  /**
   * Used to store first 4 members.
   * Initialized to 4 placeholder values so we can have a 'loading' state.
   */
  public humanitarianMembers: any[] = [];

  /**
   * Gets all members, sorts them by order param and saves first four.
   * 
   * @param memberService Used to get the first four members of RA.
   */
  constructor(
    private memberService: MemberService,
    private navigationService: PageNavigationService
  ) {
    this.memberService.members.subscribe((res) => {
      const members = res.sort((a, b) => a.order - b.order);
      this.humanitarianMembers = [];
      for(const member of members) {
        if (humanitarianRoles.find(role => member.role.toLowerCase().includes(role.toLowerCase()))) {
          this.humanitarianMembers.push(member);
          if (humanitarianRoles.length === 4) {
            break;
          }
        }
      }
    });
  }

  public goTo(id: string, route?: any[]) {
    this.navigationService.scrollTo(id, route);
  }
}
