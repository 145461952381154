<div class="chip">
    {{'pages.home.hiring.chip' | translate}}
    <div class="masked">
        <img src="/assets/images/logos/ra-hand.png"/>
    </div>
</div>
<h2>{{'pages.home.hiring.title' | translate}}</h2>
<p>
    {{'pages.home.hiring.p1' | translate}}
    <br>
    {{'pages.home.hiring.p2' | translate}}
</p>
<app-button [style]="'flat'" [color]="'black'" [arrow]="true">
    {{'pages.home.hiring.button' | translate}}
</app-button>
