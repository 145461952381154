import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ProjectCard, ProjectCardAdapter } from './project-card.model';
import { ProjectDetails, ProjectDetailsAdapter } from './project-details.model';
import { ProjectImages, ProjectImagesAdapter } from './project-image.model';
import {
  ProjectTestimonial,
  ProjectTestimonialAdapter,
} from './project-testimonial';
import { Gallery } from './project-gallery.model';

/**
 *
 */
export interface Project {
  id: string;
  name: string;
  order: number;
  brandAllias: string;
  quickDesc: string;
  fullDesc: string;
  themeColor: string;
  cards: ProjectCard[];
  details: ProjectDetails;
  imgs: ProjectImages;
  videoId: string;
  testimonial: ProjectTestimonial | null;
  gallery: Gallery[];
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectAdapter implements Adapter<Project> {
  /**
   * The constructor
   *
   * @param detailsAdapter the details model
   * @param cardAdapter the card model
   * @param imgsAdapter the image model
   * @param testimonialsAdapter the testimonial model
   */
  constructor(
    private detailsAdapter: ProjectDetailsAdapter,
    private cardAdapter: ProjectCardAdapter,
    private imgsAdapter: ProjectImagesAdapter,
    private testimonialsAdapter: ProjectTestimonialAdapter
  ) {}

  /**
   * the adapt function
   *
   * @param item the item to adapt into project
   * @returns Project
   */
  adapt(item: any): Project {
    const newCards: any[] = [];
    const project: Project = {
      id: item.id,
      name: item.name,
      order: item.order,
      brandAllias: item.brandAllias,
      quickDesc: item.quickDescription,
      fullDesc: item.fullDesc,
      themeColor: item.themeColor,
      cards: this.adaptCards(item.cards, newCards),
      details: this.detailsAdapter.adapt(item.details),
      imgs: this.imgsAdapter.adapt(item.imgs),
      videoId: item.videoId,
      gallery: item.gallery ? item.gallery : [],
      testimonial: item.testimonials
        ? this.testimonialsAdapter.adapt(item.testimonials)
        : null,
    };
    project.cards = newCards;
    return project;
  }

  /**
   * The function to adapt the card project
   *
   * @param cards cards to adapt
   * @param newCards the list of adapted cards
   * @returns ProjectCard
   */
  adaptCards(cards: any, newCards: ProjectCard[]) {
    if (typeof cards === 'boolean') {
      return [];
    } else if (typeof cards === 'object') {
      return cards.forEach((element: any) =>
        newCards.push(this.cardAdapter.adapt(element))
      );
    }
  }
}
