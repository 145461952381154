import { Component, OnDestroy } from '@angular/core';
import { CarrouselComponent } from '../shared/carrousel/carrousel.component';
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-projects-row',
  standalone: true,
  imports: [CarrouselComponent, ButtonComponent, TranslateModule],
  templateUrl: './projects-row.component.html',
  styleUrl: './projects-row.component.scss'
})
export class ProjectsRowComponent implements OnDestroy {

  public projects: Project[] = [];
  private subscription = new Subscription();

  /**
   * Gets all projects and sorts them.
   * 
   * @param memberService Used to get the first four members of RA.
   */
  constructor(
    private projectService: ProjectService,
  ) {
    this.subscription.add(this.projectService.projects.subscribe((res) => {
      this.projects = res.sort((a, b) => b.order - a.order);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
