<div class="page-container">
    <h1 style="font-size:1.5rem; margin-bottom: 1em" class="title">TERMS OF SERVICES</h1>
    <p>You are now connected to the <em>Relief Applications</em> public Internet Site. The information and content contained herein are provided subject to the following Terms&amp;Conditions and your access to this web site and continued usage constitutes complete acceptance of these terms and conditions: The present site is intended to provide information about <em>Relief Applications</em>, its organization, the products and services offered.</p>

    <p>Unless otherwise stated, the content of this site including, but not limited to, the text and images contained herein and their arrangement are the property of <em>Relief Applications</em>. All trademarks used or referred to in this website are the property of their respective owners. Nothing contained in this site shall be construed as conferring any license or right to any copyright, patent, trademark or other proprietary interest of <em>Relief Applications</em> or any third party. This site and the content provided in this site, including, but not limited to, text, graphics, images, audio, video, html code, buttons, and, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of <em>Relief Applications</em>, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.</p>

    <p>The information provided on this site is free of charge and for informational purposes only and does not create a business or professional services relationship between you and <em>Relief Applications</em>.</p>

    <p>Although <em>Relief Applications</em> uses reasonable efforts to include accurate and up-to-date information in this web site, this web site may include inaccuracies, outdated information or typographical errors. Therefore, <em>Relief Applications</em> makes no warranties or representations as to the accuracy or completeness of any information contained herein. Further, <em>Relief Applications</em> assumes no liability, obligation or responsibility for any errors or omissions in the content of this site.</p>

    <p>The names, images, logos and pictures identifying <em>Relief Applications’s</em> services are proprietary marks of <em>Relief Applications</em>. References made while using this web site to any third party provided information, content, goods, products, process or service by trade name, trademark, manufacturer or otherwise does not necessarily constitute or imply the endorsement, approval or recommendation thereof or connection therewith by <em>Relief Applications</em>. Further, this web site may be linked to other web sites, which are not under the control of and are not maintained by <em>Relief Applications</em>. <em>Relief
            Applications</em> has not reviewed any or all of the sites linked to this site and <em>Relief
            Applications</em> is not responsible for the content of those sites or any of the off-site pages or any other sites linked to those sites. <em>Relief Applications</em> is providing these links to you only as a convenience, and the inclusion of any link to such sites does not imply endorsement by <em>Relief
            Applications</em> of those sites.
        <em>Relief Applications</em> does not claim ownership of the materials you provide through this website, including but not limited to feedback and suggestions. <em>Relief Applications</em> shall not be liable or held accountable for the content or views expressed in such materials provided by you. Your use of and browsing in this web site is at your own risk. Neither <em>Relief Applications</em> nor any other party involved in creating, producing, or delivering the site is liable for any direct, incidental, consequential, indirect, or punitive damages arising out of your access to, or use of, this site. Without limiting the foregoing, everything on this site is provided to you "as is" and "as available" without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or noninfringement of intellectual property rights of third parties.</p>

    <p><em>Relief Applications</em> may make modifications, improvements, corrections and/or changes to this web site and these Terms&amp;Conditions at any time with reasonable notice.</p>

    <p><em>Relief Applications</em> may collect data logs of individuals surfing on <em>Relief Applications</em> website, with the purpose of understanding aggregate usage of features and pages. For this very purpose
        <em>Relief
            Applications</em> uses Google Analytics. Addition Personal data can also be collected as a result of the use of the contact form. These Personal Data are processed by <em>Relief Applications</em> solely to deliver the appropriate support and service. None of these data will be shared with third parties. Because data protection and security matters, the <em>Relief Applications</em> website is hosted in the European Union in order to guarantee an appropriate level of protection to all data that are collected and processed. When visiting our website, cookies are installed on the computer to allow us improving the way our websites works for example by making sure users are finding what they need easily. If you don’t agree with the use of such cookies, you can set up your browser to limit or exclude their use.</p>

    <p>Subjects whose Personal Data are collected have a right to request access, amendment or erasure of their Personal Data. To exercise this right, the subjects can contact <em>Relief Applications</em> at contact&#64;
        <em>Relief
            Applications</em>.org. These Terms and Conditions shall be governed by and construed in accordance with French law. Any cause of action against <em>Relief Applications</em> must be commenced in France.</p>

    <p><strong><em>Press Releases</em></strong></p>

    <p><em>Relief Applications</em> press releases are presented on this web site for historical purposes only. The information provided by <em>Relief Applications</em> was accurate at the time of release, subject to the disclaimer about forward-looking statements, which was printed concurrently with the announcement. Investors should not assume that statements made in these documents remain in effect at a later time. <em>Relief
            Applications</em> does not review past postings to determine whether they remain accurate, and information contained in such postings may be superseded. <em>Relief Applications</em> disclaims any obligation to update the statements or press releases. The financial information that may be accessed through this page speaks only as of the particular date or dates of its origination. Such information may have become out of date. <em>Relief Applications</em> does not undertake any obligation, and disclaims any duty, to update any of the information available through this page. <em>Relief Applications’s</em> future financial performance is subject to various trends, risks and uncertainties that could cause actual results to differ materially from those expressed in any forward-looking statements.
        <strong>IF THESE TERMS AND CONDITIONS ARE NOT ACCEPTED IN FULL, THE USE OF THIS WEB SITE MUST BE TERMINATED
            IMMEDIATELY.</strong></p>

    <p><em>Relief Applications</em>, the <em>Relief Applications</em> logo, Emalsys and the Emalsys logo are registered trademarks of the <em>Relief Applications</em> group.</p>

    <p><a aria-label="Link to Reliefapps.com" href="https://reliefapps.com/">Reliefapps.com</a> is registered and owned by Relief Applications, a limited-liability company, duly organized and validly existing under the laws of France having its registered office at: 10 rue Guy de Maupassant 31200, Toulouse, France whose company SIRET number is 804995207.</p>
</div>
