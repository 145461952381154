import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

/**
 *
 */
export interface ProjectDetails {
  id: string;
  service: string;
  industry?: string;
  timeFrame: string;
  teamSize: string;
  technologies: string[];
  goals: number[];
  tags: string[];
  newTags: string[];
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectDetailsAdapter implements Adapter<ProjectDetails> {
  /**
   * Function for create the different ProjectDetails
   *
   * @param item the ProjectDetails to create
   * @returns ProjectDetails
   */
  adapt(item: any) {
    const projectDetails: ProjectDetails = {
      id: item.id,
      service: item.service,
      industry: item.industry,
      timeFrame: item.timeFrame,
      teamSize: item.teamSize,
      technologies: item.technologies?.split(';;'),
      goals: item.goals?.split(', '),
      tags: item.tag?.split(';;'),
      newTags: item.new_tag,
    };
    return projectDetails;
  }
}
