import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PageNavigationService {

  constructor(private routeService: Router) { }

  scrollTo(id: string, altRoute?: any[]) {
    const el = document.getElementById(id);
    if (!el && altRoute) {
      this.routeService.navigate(altRoute).then(() => {
        // Find a better way to wait for the page to fully load
        setTimeout(() => {this.scrollTo(id)}, 400);
      });
    } else if (el) {
      console.log(el);
      el.scrollIntoView({behavior: 'smooth'});
    }
  }
}
