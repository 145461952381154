import { Injectable } from '@angular/core';
import { Member, MemberAdapter } from '../models/member.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';

const MEMBERS_ROUTE = '/members';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private $members = new BehaviorSubject<Member[]>([]);

  /**
   * The constructor
   *
   * @param api the api service
   * @param memberAdapter the adapter for each member
   */
  constructor(private api: ApiService, private memberAdapter: MemberAdapter) {
    const url = environment.apiUrl + MEMBERS_ROUTE;
    this.api.get(url).subscribe((res) => {
      this.$members.next(res.map((x: any) => this.memberAdapter.adapt(x)));
    });
  }

  /**
   * Get the observable of the member
   *
   * @returns Observable
   */
  get members(): Observable<Member[]> {
    return this.$members.asObservable();
  }
}
