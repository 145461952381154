<div class="content-container">
    <div class="content">
        <div class="newsletter">
            <div class="logo" routerLink="/home"></div>
            <p>{{'component.footer.newsletter.joinCommunity' | translate}}</p>
            <h4>{{'component.footer.newsletter.signUp' | translate}}</h4>
                <mat-form-field class="dark">
                    <!-- <mat-label>{{"component.footer.newsletter.email" | translate}}</mat-label> -->
                    <input matInput [placeholder]="'component.footer.newsletter.email' | translate" [formControl]="newsletterEmail">
                    @if (newsletterEmail.hasError('email') && !newsletterEmail.hasError('required')) {
                        <mat-error>{{'shared.error.email' | translate}}</mat-error>
                      }
                </mat-form-field>
            <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" [disabled]="!this.newsletterEmail.valid" (click)="onSubscribe()">
                {{"component.footer.newsletter.subscribe" | translate}}
            </app-button>
        </div>
        <div class="navigation">
            <div>
                <h4>{{'component.footer.navigation' | translate}}</h4>
                <p class="link" *ngFor="let item of navItems" [routerLink]="item.nav">
                    {{item.key | translate}}
                </p>
            </div>
            <div>
                <h4>{{'component.footer.servicesSolutions' | translate}}</h4>
                <p class="link" *ngFor="let item of serviceItems.children" [routerLink]="[serviceItems.nav, item.nav]">
                    {{item.key | translate}}
                </p>
            </div>
        </div>
    </div>
    <div class="bottom">
        <a href="https://www.linkedin.com/company/relief-applications" class="linkedin-logo"></a>
        <p>{{('component.footer.reservedRights' | translate) + ' © ' + currentYear}}</p>
        <div>
            <p routerLink="privacy-policy" class="link">{{'component.footer.privacy' | translate}}</p>
            <p routerLink="terms-of-services" class="link">{{'component.footer.terms' | translate}}</p>
        </div>
    </div>
</div>