import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * The constructor
   *
   * @param http the http client service
   */
  constructor(private http: HttpClient) {}

  /**
   * The get request
   *
   * @param path the path of the api
   * @param options the options for the response
   * @returns Observable<any>
   */
  get(path: string, options?: any): Observable<any> {
    return this.http.get<any>(path, options);
  }
}
