import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';


/**
 * Icon has to be one of the custom svg defined on "app.component.ts"
 * Title and content have to be translation keys.
 */
export type CustomListContent = {icon: string, title: string, content: string}[];

@Component({
  selector: 'app-custom-list',
  standalone: true,
  imports: [CommonModule, MatIcon, TranslateModule],
  templateUrl: './custom-list.component.html',
  styleUrl: './custom-list.component.scss'
})
export class CustomListComponent {

  /**
   * Used to define the style used by the component
   */
  @Input() variant: 'gray' | 'red' = 'gray'
  /**
   * Used to define the content of the component.
   */
  @Input() content?: CustomListContent;

}
