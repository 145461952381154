import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { navigationItems } from '../../../../constants/navigation';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../shared/button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PageNavigationService } from '../../../../services/page-navigation.service';


@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [MatIconModule, CommonModule, TranslateModule, MatButtonModule, ButtonComponent, MatMenuModule, RouterModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
  public navItems = navigationItems;

  constructor(private navigationService: PageNavigationService) {}

  public stopPropagation(event: Event) {
    event.stopPropagation(); 
  }

  public goToContactForm() {
    this.navigationService.scrollTo('contactUs', ['/home']);
  }
}
