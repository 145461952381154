import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import {
  ProjectImagesCover,
  ProjectImagesCoverAdapter,
} from './project-images-cover';

/**
 *
 */
export interface ProjectImages {
  id: string;
  logo: string;
  cardWeb: string;
  cardMobile: string;
  covers: ProjectImagesCover[];
}
/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectImagesAdapter implements Adapter<ProjectImages> {
  /**
   * The constructor
   *
   * @param coverAdapter the model for the cover of an image
   */
  constructor(private coverAdapter: ProjectImagesCoverAdapter) {}

  /**
   * Function for create the different Project Image
   *
   * @param item the project image  to create
   * @returns ProjectImages
   */
  adapt(item: any) {
    const projectImages: ProjectImages = {
      id: item.id,
      logo: item.logo,
      cardWeb: item.cardweb,
      cardMobile: item.cardmobile,
      covers: item.covers,
      // covers: item.covers.forEach(element => this.coverAdapter.adapt(element))
    };
    return projectImages;
  }
}
