

<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <div class="flex">
            <div>
                <h4>{{'pages.consulting.header.miniTitle' | translate}}</h4>
                <h1>{{'pages.consulting.header.title' | translate}}</h1>
            </div>
            <div>
                <p>{{'pages.consulting.header.summary1' | translate}}</p>
                <br>
                <p>{{'pages.consulting.header.summary2' | translate}}</p>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div class="sections-container">

        <app-consulting-services></app-consulting-services>

        <!-- "Projects" section -->
        <app-projects-row></app-projects-row>
    
        <!-- "Meet our Humanitarian Consultants" section -->
        <div class="flex consultants">
            <div>
                <h2>{{'pages.consulting.humanitarianConsultants.title' | translate}}</h2>
                <h4>{{'pages.consulting.humanitarianConsultants.subTitle' | translate}}</h4>
                <p>{{'pages.consulting.humanitarianConsultants.content' | translate}}</p>
                <div class="list">
                    <div *ngFor="let i of [0, 1, 2, 3]">
                        <mat-icon aria-hidden="true" svgIcon="tick_circle"></mat-icon>
                        <p>{{'pages.consulting.humanitarianConsultants.list.' + i | translate}}</p>
                    </div>
                </div>
            </div>
            <!-- Humanitarian staff -->
             <div>
                <div *ngFor="let member of humanitarianMembers">
                    <div class="image" [ngStyle]="{'background-image': 'url(' + member.img + ')'}"></div>
                    <div class="info">
                        <h4>{{member.name}}</h4>
                        <p>{{member.role}}</p>
                    </div>
                </div>
             </div>
        </div>

        <!-- "How it works" section -->
         <app-how-it-works></app-how-it-works>

        <!-- "Contact us" section -->
        <app-contact-us></app-contact-us>
    </div>
</div>