
<div class="howItWorks">
    <div class="flex">
        <div>
            <h2>{{'component.howItWorks.title' | translate}}</h2>
            <div *ngFor="let i of [0, 1, 2]">
                <div class="number">{{'0' + (i + 1) + '.'}}</div>
                <div>
                    <h4>{{'component.howItWorks.step.' + i + '.title' | translate}}</h4>
                    <p>{{'component.howItWorks.step.' + i + '.desc' | translate}}</p>
                </div>
                <div class="separator" *ngIf="i < 2"></div>
            </div>
        </div>
        <div class="img"></div>
    </div>
    <div class="custom-button" (click)="navigationSerice.scrollTo('contactUs')">
        <div class="bg"></div>
        {{'component.howItWorks.bookDemo' | translate}}
        <div class="icon">✉️</div>
    </div>
</div>