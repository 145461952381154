/**
 * String array of available subjects on for the contactUs Component.
 * Subjects are used as translations keys, make sure to add a translation entry on 'component.contactUs.subject' when adding / modifying any item.
 */
export const contactUsSubjects: string[] = [
    "itDevelopment",
    "techConsulting",
    "humanitarianConsulting",
    "apps",
    "webPlatforms",
    "chatBots",
    "fieldEquipment",
    "caseManagementSystem",
    "monitoringAndEvaluation",
    "suppplyChainManagementSystem",
    "voiceAssistants",
    "warehouseManagement",
    "dataManagementSystem",
    "RefuelingLogBook",
    "customApp"
]