<div class="page-container">
    <h1 style="font-size:1.5rem; margin-bottom: 1em" class="title">PRIVACY POLICY</h1>

    <p><strong>COMMITMENT TO YOUR PRIVACY</strong></p>
    <p>This website is owned and operated by Relief Applications. We understand you value your privacy. At Relief Applications, we want to make your online experience satisfying and safe. This statement governs information you provide to us, or we learn from your use of this website, and tells you how we may collect, use and, in some instances, share this information.</p>

    <p><strong>INFORMATION and HOW WE USE IT</strong></p>
    <p>We may ask you to provide us with personal information if you would like to participate in some activities available on, or benefit from services offered on, this website. Personal information means any information that may be used to identify an individual, including, but not limited to, your name, home address, telephone number, and e-mail address. Through the use of forms we may collect this information to help provide you with Relief Applications services, our Partners’ products and services, and respond to your requests. We may combine the information you submit under your account with information from other Relief Applications services or third parties in order to provide you with a better experience and to improve the quality of our services.</p>

    <p>Relief Applications takes users’ privacy very seriously and is committed to comply with the EU Directive on privacy in the electronic communications (2002/58/EC). For this purpose we have developed the following notice which intends to give you all the keys to manage the cookies we collect while you visit our website. </p>

    <p><strong>COOKIES</strong></p>
    <p>What is a cookie? Cookies are small files which are downloaded on to a device when the user accesses to certain websites. Cookies allow a website to recognize users, to understand the users’ navigation on different websites. In practice, the website you visit sends information to the browser which then creates a text file. Every time the user goes back to the same website, the browser retrieves and sends this file to the website's server. Relief Applications intends to ensure that you enjoy your experience on our website. For this reason, when visiting our Relief Applications website, cookies are installed on the computer.</p>

    <p>When surfing on Relief Applications website(s), data logs of your surfing activity may be collected with the purpose of understanding aggregate usage of features and pages. For this very purpose we use Google Analytics. Google Analytics enables Relief Applications to view how you interact with website content. Neither Relief Applications nor Google are able to identify you and your online activity. Cookies remain on your computer for no longer than 24 months and you can decide to delete them all anytime when going into your browser settings.</p>

    <p><strong>Cookies settings</strong></p>

    <p>If you don’t agree with the use of such cookies, you can set up your browser to limit or exclude their use. Your browser may provide you with information and control over cookies. You can set your browser to alert you when a cookie is being used, and accept or reject the cookie. You can also set your browser to refuse all cookies or accept only cookies returned to the originating servers. You can generally disable the cookie feature on your browser without affecting their ability to use the Relief Applications website(s).</p>

    <p><strong>Purpose of the cookies</strong></p>

    <p>Please note that these settings are only valid for the pages created and owned by Relief Applications. When we embody link to external websites and that you click on these links you leave our website and therefore the settings and policy applying to our website no longer applies. You should consider the new website cookies policy and settings. We process personal information as described above and also for such purposes as:
        <br> - to provide users with products and services from Relief Applications;
        <br> - to conduct research and analysis in order to maintain and improve our products and services;
        <br> - to alert you to updated information and other new services (you may opt-out of these communications if you choose so);
        <br> - to contact you in response to requests for information such as ‘Contact Us’.</p>

    <p><strong>User Access</strong></p>

    <p>We make a good faith effort to provide you with access to your personal information, to correct data that may be inaccurate, or delete data at your request. We may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others, requests that would be extremely impractical, or where information is required to be retained by law or for legitimate business purposes.</p>

    <p><strong>TRANSFER AND INFORMATION SHARING POLICY</strong></p>

    <p>We do not share personal information collected on this website with third parties for their direct marketing purposes. We may provide personal information to, or permit access to personal information by our vendors and service providers for the purpose of processing personal information on our behalf, or where such access is incident to their assisting us. Relief Applications does not otherwise share personal information about website users with third parties unless doing so is appropriate to carry out a user’s request or it reasonably believes that doing so is legally required or is in Relief Applications’ interest to protect its property or other legal rights or the rights or property of others.</p>

    <p>Affiliated Websites This Privacy Policy applies to websites and services that are owned and operated by Relief Applications. We do not exercise control over the websites of third parties that you may link to or view as part of a frame on a page on this website. These other sites may place their own cookies or other files on your computer, collect data, or solicit personal information from you. We are not responsible for the actions or policies of such third parties. You should check the applicable privacy policies of those third parties when providing information on a feature or page operated by a third party.</p>

    <p><strong>SECURITY</strong></p>

    <p>No data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk. Once we receive your transmission, we make reasonable efforts to ensure security on our systems. If Relief Applications learns of a security systems breach, we may attempt to notify you electronically so that you can take appropriate protective steps. By using this website or providing personal information to us, you agree that we can communicate with you electronically regarding security, privacy and administrative issues relating to your use of this website. Relief Applications may post a notice on our website if a security breach occurs, or send an email to you at the email address you have provided to us in these circumstances.
    </p>

    <p>Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice), you should notify us at contact&#64;reliefapplications.org .</p>

    <p><strong>CHANGES AND UPDATES</strong></p>
    <p>We may occasionally update this Privacy Policy. When we do, we will also revise the ‘last updated’ on the Privacy Policy. For changes to this Privacy Policy that may be materially less restrictive on our use, or disclosure of personal information you have provided to us, we will attempt to obtain your consent before implementing the change. </p>
    <p>We encourage you to periodically review this Privacy Policy to stay informed about how we are protecting the Personal Information we collect. Your continued use of this website constitutes your agreement to this Privacy Statement and any updates. </p>

    <p><strong>CONTACTING RELIEF APPLICATIONS</strong></p>
    <p>If you have any questions, comments, or concerns about Relief Applications or this Privacy Policy, please email us at contact&#64;reliefapplications.org .</p>

    <p>In the event that we are still unable to resolve your concerns, Relief Applications is committed to cooperating to achieve a proper resolution of your privacy concerns.</p>

    <p><a aria-label="Link to Reliefapps.com" href="https://reliefapps.com/">Reliefapps.com</a> is registered and owned by Relief Applications, a limited-liability company, duly organized and validly existing under the laws of France having its registered office at: 10 rue Guy de Maupassant 31200, Toulouse, France whose company SIRET number is 804995207.</p>

    <p><strong><em>Last updated on</em></strong>: 19th December 2018</p>
</div>