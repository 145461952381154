<div class="icon-list" [ngClass]="[variant]" *ngIf="content">
    <div *ngFor="let item of content">
        <div>
            <mat-icon aria-hidden="true" [svgIcon]="item.icon"></mat-icon>
        </div>
        <div>
            <h4>{{item.title | translate}}</h4>
            <p>{{item.content | translate}}</p>
        </div>
    </div>
</div>