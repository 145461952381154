import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

/**
 *
 */
export interface ProjectImagesCover {
  id: string;
  link: string;
  orientation: string;
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectImagesCoverAdapter implements Adapter<ProjectImagesCover> {
  /**
   * Function for create the different project cover image
   *
   * @param item the project cover to create
   * @returns ProjectImagesCover
   */
  adapt(item: any) {
    const projectImageCover = {
      id: item.id,
      link: item.link,
      orientation: item.orientation,
    };
    return projectImageCover;
  }
}
