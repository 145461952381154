import { Component } from '@angular/core';
import { CarrouselComponent } from "../shared/carrousel/carrousel.component";
import { availableSuiteApps } from '../../constants/suite-apps';

@Component({
  selector: 'app-suites-row',
  standalone: true,
  imports: [CarrouselComponent],
  templateUrl: './suites-row.component.html',
  styleUrl: './suites-row.component.scss'
})
export class SuitesRowComponent {
  public suites = availableSuiteApps;
}
