<ng-container>
    <h4>{{'component.contactUs.miniTitle' | translate}}</h4>
    <h2>{{'component.contactUs.title' | translate}}</h2>
    <div class="flex">
        <div>
            <h4>{{'component.contactUs.chooseSubject' | translate}}</h4>
            <div class="chip-list">
                <p class="chip" *ngFor="let subject of subjects"
                    [class.selected]="contactForm.controls.subject.value?.includes(subject)"
                    (click)="contactForm.controls.subject.setValue(subject)"
                >
                    {{'component.contactUs.subject.' + subject | translate}}
                </p>
            </div>
        </div>
        <div>
            <form [formGroup]="contactForm">
                <mat-form-field [hideRequiredMarker]="true">
                    <mat-label>{{'component.contactUs.name' | translate}}*</mat-label>
                    <input matInput formControlName="name">
                    @if (contactForm.controls.name.hasError('required')) {
                      <mat-error>{{'shared.error.required' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true">
                    <mat-label>{{'component.contactUs.email' | translate}}*</mat-label>
                    <input matInput formControlName="email">
                    @if (contactForm.controls.email.hasError('required')) {
                      <mat-error>{{'shared.error.required' | translate}}</mat-error>
                    }
                    @if (contactForm.controls.email.hasError('email') && !contactForm.controls.email.hasError('required')) {
                        <mat-error>{{'shared.error.email' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'component.contactUs.phone' | translate}}</mat-label>
                    <input matInput formControlName="phone">
                    @if (contactForm.controls.phone.hasError('pattern')) {
                      <mat-error>{{'shared.error.phone' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true">
                    <mat-label>{{'component.contactUs.organization' | translate}}*</mat-label>
                    <input matInput formControlName="organization">
                    @if (contactForm.controls.organization.hasError('required')) {
                      <mat-error>{{'shared.error.required' | translate}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'component.contactUs.summary' | translate}}</mat-label>
                    <input matInput formControlName="summary">
                </mat-form-field>
            </form>
            <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" [disabled]="!contactForm.valid" (click)="submitContactRequest()">
                {{"shared.talk" | translate}}
            </app-button>
        </div>
    </div>

</ng-container>