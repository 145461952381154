export const availableApps: any[] = [
    {
        title: "WMS",
        subTitle: "Warehouse Management System",
        iconURL: "/assets/images/apps/wms/icon.png",
        coverURL: "/assets/images/apps/wms/cover.png"
    },
    {
        title: "CMS",
        subTitle: "Case Management",
        iconURL: "/assets/images/apps/cms/icon.png",
        coverURL: "/assets/images/apps/cms/cover.png"
    },
    {
        title: "RMS",
        subTitle: "Refuelling Logbook",
        iconURL: "/assets/images/apps/rms/icon.png",
        coverURL: "/assets/images/apps/rms/cover.png"
    },
    {
        title: "Custom",
        subTitle: "Your Custom App",
        iconURL: "/assets/images/apps/custom/icon.png",
        coverURL: "/assets/images/apps/custom/cover.png"
    },
]